import "./layout.css"

require("typeface-mukta")


const baseFontSize =  18;
const fontScale =  4;
const baseSpacing = 27;

const fontSizing = factor => ({
 size: `${baseFontSize + factor * fontScale}px`,
 height: `${baseSpacing + factor * fontScale}px`,
 // maxWidth chosen to be ~50 characters wide
 // see: https://ux.stackexchange.com/a/34125
 maxWidth: `${baseSpacing * (baseFontSize + factor * fontScale)*1.4}px`,
});

const PreglyTheme = {

  global: {
    font: {
      family: "Mukta",
    },
    colors: {
      brand: "#009d52"
    }
  },

  button: {
      border: {
        radius: undefined,

      },

      padding: {
        vertical: "12px",
        horizontal: "24px"
      },

  },

  heading: {
    font: {



         },
    level: {
            1: {
              font: {
                 weight: "800",
                // weight: undefined,
              },
              xsmall: { ...fontSizing(1) },
              small: { ...fontSizing(2) },
              medium: { ...fontSizing(6) },
              large: { ...fontSizing(15) },
              xlarge: { ...fontSizing(21) },
            },
            2: {
              font: {
                // family: undefined,
                // weight: undefined,
              },
              small: { ...fontSizing(2) },
              medium: { ...fontSizing(4) },
              large: { ...fontSizing(8) },
              xlarge: { ...fontSizing(12) },
            },
            3: {
              font: {
                // family: undefined,
                // weight: undefined,
              },
              small: { ...fontSizing(1) },
              medium: { ...fontSizing(2) },
              large: { ...fontSizing(4) },
              xlarge: { ...fontSizing(6) },
            },
            4: {
              font: {
                // family: undefined,
                // weight: undefined,
              },
              small: { ...fontSizing(0) },
              medium: { ...fontSizing(0) },
              large: { ...fontSizing(0) },
              xlarge: { ...fontSizing(0) },
            },
            5: {
              font: {
                // family: undefined,
                // weight: undefined,
              },
              small: { ...fontSizing(-0.5) },
              medium: { ...fontSizing(-0.5) },
              large: { ...fontSizing(-0.5) },
              xlarge: { ...fontSizing(-0.5) },
            },
            6: {
              font: {
                // family: undefined,
                // weight: undefined,
              },
              small: { ...fontSizing(-1) },
              medium: { ...fontSizing(-1) },
              large: { ...fontSizing(-1) },
              xlarge: { ...fontSizing(-1) },
            },
          },
          responsiveBreakpoint: 'small', // when we scale the font size down
            weight: 400,
        },

        paragraph: {
            small: { ...fontSizing(0) },
            medium: { ...fontSizing(1) },
            large: { ...fontSizing(2) },
            xlarge: { ...fontSizing(4) },
            xxlarge: { ...fontSizing(6) },
          },
};

export default PreglyTheme;
