
import PropTypes from "prop-types"
import React, { useState } from "react";
import {Button,ThemeContext, Anchor, Box, Header as GrommetHeader, ResponsiveContext, Nav, Layer } from "grommet"
import logoLarge from '../images/pregly-logo-no-border.svg'
import logoSmall from '../images/pregly-small-logo.png'
import  {Menu, Close, Facebook, Twitter}  from 'grommet-icons'
import Link from "../components/link"
const myAnchor = {
  anchor: {
    extend: `

      font-weight: 600;`
  },
  text: {
    extend: `

      font-weight: 600;`
  },

};


const Header = () => {
  const [showLayer, setShowLayer] = React.useState(false);

return (
  <GrommetHeader background="brand"  pad="medium" align="start" justify="center">
  <ThemeContext.Extend value={myAnchor}>

      <Box direction="row" width="xlarge"  justify="between">



          <ResponsiveContext.Consumer>
          {responsive =>
            responsive === "small" ? (
              <Box align="start" height="xxsmall" width="xxsmall" justify="center">
                <Link to="/"><img  src={logoSmall}/></Link>
              </Box>
              ) : (



        <ThemeContext.Extend value={myAnchor}>
          <Box>
          <Box align="start" height="xsmall" width="small" justify="center">
          <Link to="/">  <img  src={logoLarge}/></Link>
          </Box>
            <Nav direction="row" gap="large" align="center" >

                    <Anchor color="light-1" size="xlarge" label="Getting Pregnant" href="/getting-pregnant" />
                    <Anchor color="light-1" size="xlarge" label="Pregnancy" href="/pregnancy" />
                    <Anchor color="light-1" size="xlarge" label="Giving Birth" href="/giving-birth"/>
                    <Anchor color="light-1" size="xlarge" label="Baby Names" href="/baby-names" />
                    <Anchor color="light-1" size="xlarge" label="Community" href="/forum" />
            </Nav>
            </Box>
          </ThemeContext.Extend>
        )
       }
     </ResponsiveContext.Consumer>

          <Box   justify="center">
            <Button   onClick={() => setShowLayer(!showLayer)} icon={<Menu color="white" size="medium" />}/>
          </Box>
          {showLayer && (
                    <Layer position="top" animation="fadeIn">


                      <Box fill  background="brand"  pad="medium" align="center" justify="start">

                        {/* header start */}
                          <Box direction="row" width="xlarge"  justify="between">
                              <ResponsiveContext.Consumer>
                              {responsive =>
                                responsive === "small" ? (
                                  <Box align="start" height="xxsmall" width="xxsmall" justify="center">
                                    <Link to="/"><img  src={logoSmall}/></Link>
                                  </Box>
                                ) : (

                                   <Box align="start" height="xsmall" width="small" justify="center">
                                     <Link to="/"><img  src={logoLarge}/></Link>
                                   </Box>
                                 )}
                             </ResponsiveContext.Consumer>

                              <Box   justify="center">
                                <Button   onClick={() => setShowLayer(false)} icon={<Close color="white" size="medium" />}/>
                              </Box>
                          </Box>
                          {/* header end */}

                          {/* content start */}
                          <Box direction="column" width="xlarge" align="center" justify="between" pad="large" gap="small">
                            <Anchor color="light-1" size="large" label="Getting Pregnant" href="/getting-pregnant" />
                            <Anchor color="light-1" size="large" label="Pregnancy" href="#" />
                            <Anchor color="light-1" size="large" label="Baby & Toddler" href="#" />
                          </Box>
                          <Box direction="column" width="xlarge" align="center" justify="between" pad="large" gap="small">
                            <Anchor color="light-1" size="large" label="Blog" href="/blog" />
                            <Anchor color="light-1" size="large" label="Community" href="/forum" />
                          </Box>
                          <Box direction="row" width="xlarge" align="center" justify="center" pad="large" gap="small">
                            <Facebook/><Twitter/>
                          </Box>
                          {/* content end */}

                    </Box>
                  </Layer>
                  )}



      </Box>






</ThemeContext.Extend>
  </GrommetHeader>
)}


export default Header
