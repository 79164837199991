import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import {PreglyFooter} from "./PreglyFooter"
import "./layout.css"
import { Grommet,  Box } from "grommet"
import PreglyTheme from "./PreglyTheme"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Grommet theme={PreglyTheme} style={{ display: "flex", flexDirection: "column", }} >
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box as="main"  flex overflow="visible" align="center" >
          {children}
      </Box>
      <PreglyFooter/>
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
