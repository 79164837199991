import React from "react"
import { ResponsiveContext, Anchor,Box, Footer, Text } from "grommet"
import logoLarge from '../../images/pregly-logo-no-border.svg'
import  { Facebook, Twitter }  from 'grommet-icons'

const DisclaimerColumn = () => (
  <Box margin={{"bottom":"large"}}>
      <Anchor color="light-1" size="medium" label="Disclaimer" href="#" />
      <Anchor color="light-1" size="medium" label="Privacy Policy" href="#" />
      <Anchor color="light-1" size="medium" label="Blog" href="/blog" />
      <Anchor color="light-1" size="medium" label="Download the App" href="#" />
  </Box>
)

const CommunityColumn = () => (
  <Box margin={{"bottom":"large"}}>
    <Anchor color="light-1" size="medium" label="Community" href="/forum" />
    <Anchor color="light-1" size="medium" label="Rules" href="#" />
    <Anchor color="light-1" size="medium" label="FAQ" href="#" />
  </Box>
)

const SocialsColumn = () => (
  <Box align="start" >
    <Anchor color="light-1" size="medium" label="About us" href="#" />
    <Anchor color="light-1" size="medium" label="Contact us " href="#" />

    <Box direction="row"   gap="medium" margin={{"top":"large"}} >
      <Facebook/><Twitter/>
    </Box>

    <Box  height="xsmall" width="xsmall" justify="center"  margin={{"vertical":"medium"}} >
      <img src={logoLarge}/>
    </Box>

    <Text  size="medium">
      All Content © Pregly {new Date().getFullYear()} All Rights Reserved.
    </Text>
  </Box>
)


 const PreglyFooter = () => (
   <Footer background="brand" justify="center" pad="large">

     <ResponsiveContext.Consumer>

     {responsive =>
       responsive === "small" ? (

          <Box direction="column" width="xlarge" >
            <DisclaimerColumn/>
            <CommunityColumn/>
            <SocialsColumn/>
          </Box>

       ) : (

          <Box width="xlarge"  >
              <Box direction="row" justify="between" >
                <SocialsColumn/>
                <DisclaimerColumn/>
                <CommunityColumn/>
              </Box>
          </Box>
        )}

    </ResponsiveContext.Consumer>

   </Footer>
)

export default PreglyFooter
